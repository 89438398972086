import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/v2/Home/Customers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/v2/Home/Features/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/v2/Home/Hero.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/v2/Home/linkedin-in.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/v2/Home/Pricing/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/v2/Home/ScalisLogo.svg");
;
import(/* webpackMode: "eager" */ "/app/components/v2/Home/x-twitter.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.8_@babel+core@7.24.5_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_yhgtkmxmzhx37pfgsv2nurf6vy/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.8_@babel+core@7.24.5_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_yhgtkmxmzhx37pfgsv2nurf6vy/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarDefault"] */ "/app/scalis-components/team/navbar-variations/default/navbar-default.tsx");
